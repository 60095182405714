<mat-sidenav-container>
  <mat-sidenav #matSidenav mode="over" [autoFocus]="false">
    <div class="bukam-menu-wrapper">
      <bukam-menu
        (logout)="logout()"
        (menuClose)="matSidenav.close()"
      ></bukam-menu>
    </div>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>
