import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  ['debug', 'info', 'log', 'warn'].forEach((key) => {
    window.console[key] = () => {};
  });
}

Sentry.init({
  dsn: environment.production
    ? 'https://d126094f66164dc2a14c86fa5acdd7f2@o1077493.ingest.sentry.io/6276372'
    : null,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  release: environment.appVersion,
  tracesSampleRate: 0,
  ignoreErrors: ['Non-Error exception captured'],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
