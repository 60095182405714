<header class="header mat-elevation-z2">
  <i
    *ngIf="!hideMenuButton && (isLoggedIn$ | async)"
    class="ic-menu sidenav-button"
    (click)="toggleSidenav()"
  ></i>
  <a *ngIf="!hideMenuButton" class="logo" href="{{ bukOrigin }}"
    ><i class="ic-buklogo"></i
  ></a>
  <ng-content></ng-content>
</header>
