import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChannelTalkService {
  constructor() {
    this.loadScript();
  }

  loadScript(): void {
    const w = window as any;
    if (w.ChannelIO) {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, 
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, 
    const ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, 
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      // eslint-disable-next-line import/no-deprecated
      s.charset = 'UTF-8';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if ((window as any).attachEvent) {
      (window as any).attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot(settings: any): void {
    window.ChannelIO('boot', settings);
  }

  shutdown(): void {
    window.ChannelIO('shutdown');
  }
}
