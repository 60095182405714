import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialCommonModule } from '../material-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { ChartsModule } from 'ng2-charts';
import { Angulartics2Module } from 'angulartics2';

import { UserDebugInfoComponent } from './components/user-debug-info/user-debug-info.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { BukOembedCardComponent } from './components/buk-oembed-card/buk-oembed-card.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MomentPeriodDatepickerComponent } from './components/moment-period-datepicker/moment-period-datepicker.component';
import { ChartComponent } from './components/chart/chart.component';
import { TrackingInfoFormComponent } from './components/tracking-info-form/tracking-info-form.component';
import { LineEditorComponent } from './components/line-editor/line-editor.component';
import { BukTicketFormComponent } from './components/buk-ticket-form/buk-ticket-form.component';
import { BukTicketGroupSummaryViewComponent } from './components/buk-ticket-group-summary-view/buk-ticket-group-summary-view.component';
import { IENotiBarComponent } from './components/ie-noti-bar/ie-noti-bar.component';

import { PeriodTranslatePipe } from './pipes/period-translate.pipe';
import { PlaintextPipe } from './pipes/plaintext.pipe';
import { PlainLengthPipe } from './pipes/plainlength.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { PricePipe } from './pipes/price.pipe';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { NaverTrackingUrlPipe } from './pipes/naver-tracking-url.pipe';
import { ResizePipe } from './pipes/resize.pipe';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    ChartsModule,
    Angulartics2Module,
  ],
  declarations: [
    UserDebugInfoComponent,
    ImageInputComponent,
    ConfirmDialogComponent,
    BukOembedCardComponent,
    CommonHeaderComponent,
    MomentPeriodDatepickerComponent,
    PaginatorComponent,
    ChartComponent,
    TrackingInfoFormComponent,
    LineEditorComponent,
    PeriodTranslatePipe,
    PlaintextPipe,
    PlainLengthPipe,
    ToDatePipe,
    PricePipe,
    CurrencyNumberPipe,
    NaverTrackingUrlPipe,
    ResizePipe,
    BukTicketFormComponent,
    BukTicketGroupSummaryViewComponent,
    IENotiBarComponent,
  ],
  exports: [
    UserDebugInfoComponent,
    ImageInputComponent,
    ConfirmDialogComponent,
    BukOembedCardComponent,
    CommonHeaderComponent,
    MomentPeriodDatepickerComponent,
    PaginatorComponent,
    ChartComponent,
    TrackingInfoFormComponent,
    LineEditorComponent,
    PeriodTranslatePipe,
    PlaintextPipe,
    PlainLengthPipe,
    ToDatePipe,
    PricePipe,
    CurrencyNumberPipe,
    NaverTrackingUrlPipe,
    ResizePipe,
    BukTicketFormComponent,
    BukTicketGroupSummaryViewComponent,
    IENotiBarComponent,
  ],
  providers: [CurrencyNumberPipe],
})
export class SharedModule {}
