import i18nCacheBusting from './i18n-cache-busting';

export const environment = {
  production: true,
  appVersion: 'dev',
  editorVersion: '0.0.0',
  i18nCacheBusting,
  apiEndpoint: 'https://dev.buk.io/api/v1',
  regacyAPIEndpoint: `https://dev.buk.io/api`,
  graphApiEndpoint: 'https://graph.dev.buk.io',
  bukOrigin: 'https://dev.buk.io',
  journalUrl: 'https://dev.journal.kr',
  editorClipDeck: '240.0.0.1',
  editorClipDeckEn: '240.0.0.4',
  editorBookmarkedClipListItems: {
    manage: '190.100.1.24',
    placeholder: '190.100.1.22',
    manageEn: '190.100.1.29',
    placeholderEn: '190.100.1.28',
  },
};
